jQuery(document).ready(function($) {

	// Load more PRODUCTS in search results
	$('#search_results_load_more_products').on('click', function(){
		var query = $(this).data('query');
		var offset = $(this).data('offset');
		var items_per_page = $(this).data('items-per-page');

		jQuery.ajax({
			method: 'GET',
			url: '/ajax/search-results-load-more-products',
			data: {
				q: query,
				offset: offset,
				items_per_page: items_per_page,
			},
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			success: function(data) {
				$('#search_results_products').append(data);

				if (parseInt($('#search_results_products').data('count')) > offset + items_per_page)
				{
					$('#search_results_load_more_products').data('offset', offset + items_per_page);
				}
				else
				{
					$('#search_results_load_more_products').hide();
				}
			},
			error: function(data) {
				alert("There was a problem. Please try again...");
			}
		});
	});

	// Load more ENTRIES in search results
	$('#search_results_load_more_entries').on('click', function(){
		var query = $(this).data('query');
		var offset = $(this).data('offset');
		var items_per_page = $(this).data('items-per-page');

		jQuery.ajax({
			method: 'GET',
			url: '/ajax/search-results-load-more-entries',
			data: {
				q: query,
				offset: offset,
				items_per_page: items_per_page,
			},
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			success: function(data) {
				$('#search_results_entries').append(data);

				if (parseInt($('#search_results_entries').data('count')) > offset + items_per_page)
				{
					$('#search_results_load_more_entries').data('offset', offset + items_per_page);
				}
				else
				{
					$('#search_results_load_more_entries').hide();
				}
			},
			error: function(data) {
				alert("There was a problem. Please try again...");
			}
		});
	});
});
