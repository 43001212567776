jQuery(document).ready(function($){

	$(document).on('click', '.modal_wrapper', function(event)
	{
		$(this).removeClass('visible');
	});

	$(document).on('click', '.modal', function(event)
	{
		event.stopPropagation();
	});

	$(document).on('click', '.modal .close', function(event)
	{
		$(this).closest('.modal_wrapper').removeClass('visible');
	});

});
