var current_category_id = null;

var confirmed_filter_tag_ids = [];
var selected_filter_tag_ids = [];

function set_selected_filter_tags()
{
	$('#get_gardening_tag_filters .tag_btn').each(function(){
		var tag_id = String($(this).data('tag-id'));
		if (tag_id)
		{
			if (confirmed_filter_tag_ids.indexOf(tag_id) !== -1)
			{
				$(this).addClass('selected');
			}
			else
			{
				$(this).removeClass('selected');
			}
		}
	});

	// Disable the button by default
	$('#get_gardening_apply_tag_filters').prop('disabled', true);
}

function fetch_get_gardening_entries()
{
	confirmed_filter_tag_ids = [...selected_filter_tag_ids];

	// Loading state
	$('#get_gardening_category_filters .options').fadeOut(150);
	$('#get_gardening_tag_filters .inner').fadeOut(150);
	
	// Update URL
	var query = '?'

	if (current_category_id)
	{
		query += 'category=' + current_category_id + '&';
	}

	for (let index = 0; index < confirmed_filter_tag_ids.length; index++) {
		query += 'tags[]=' + confirmed_filter_tag_ids[index] + '&';
	}

	history.pushState({}, null, encodeURI(query));

	set_selected_filter_tags();

	// Get new data by AJAX
	jQuery.ajax({
		method: 'GET',
		url: '/ajax/get-gardening-entries',
		data: {
			category: current_category_id,
			tags: confirmed_filter_tag_ids,
		},
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		success: function(data) {
			$('#get_gardening_content').html(data);
		},
		error: function(data) {
			alert("There was a problem. Please try again...");
		},
		complete: function() {
			$('#get_gardening_category_filters, #get_gardening_tag_filters').fadeOut(250);
			
			setTimeout(function()
			{
				$('#get_gardening_category_filters .options').show();
				$('#get_gardening_tag_filters .inner').show();
			}, 250);
		}
	});
}

jQuery(document).ready(function($) {

	// Get the current filters from the URL
	var url = new URL(location.href);

	current_category_id = url.searchParams.get('category');

	confirmed_filter_tag_ids = url.searchParams.getAll("tags[]");
	selected_filter_tag_ids = [...confirmed_filter_tag_ids];

	// Show the category options modal window
	$('#current_category_filter, #current_category_filter_label').on('click', function(){
		// Position the 'dropdown' below the 'select'
		$('#get_gardening_category_filters .options').css({
			top: $('#current_category_filter').offset().top + $('#current_category_filter').outerHeight(),
			left: $('#current_category_filter').offset().left,
			width: $(window).width() < 948 ? $('#current_category_filter').outerWidth() + 'px' : 'auto',
		});

		$('#get_gardening_category_filters').fadeIn(250);
	});

	// Choose a new category
	$('#get_gardening_category_filters .option').on('click', function(){
		$('#get_gardening_category_filters .option').removeClass('selected');
		var cat_id = $(this).data('category-id');
		if (cat_id)
		{
			current_category_id = cat_id;
			$(this).addClass('selected');

			$('#current_category_filter').text($(this).text())
		}
		else
		{
			current_category_id = null;
		}

		fetch_get_gardening_entries();
	});

	// Hide the category options modal window when clicking outside it
	$('#get_gardening_category_filters').on('click', function(){
		$('#get_gardening_category_filters').fadeOut('250');
	});

	// Don't hide when clicking the modal itself
	$('#get_gardening_category_filters .options').on('click', function(event){
		event.stopImmediatePropagation();
	});

	// Show the tag filters modal window
	$(document).on('click', '#show_tag_filters_btn', function(){
		$('#get_gardening_tag_filters').fadeIn('250');
	});

	// Hide the tag filters modal window when clicking the close button or the background
	$('#get_gardening_tag_filters, #get_gardening_tag_filters .close_btn').on('click', function(){
		$('#get_gardening_tag_filters').fadeOut('250');

		// After closing the modal window, reset the filters to their previous state
		selected_filter_tag_ids = [...confirmed_filter_tag_ids];
		setTimeout(function(){
			set_selected_filter_tags();
		}, 250);
	});

	// Don't hide when clicking the modal itself
	$('#get_gardening_tag_filters .inner').on('click', function(event){
		event.stopImmediatePropagation();
	});

	// Select a tag
	$('#get_gardening_tag_filters .tag_btn').on('click', function(){
		var tag_id = String($(this).data('tag-id'));
		if (tag_id)
		{
			var position_in_array = selected_filter_tag_ids.indexOf(tag_id);
			if (position_in_array === -1)
			{
				selected_filter_tag_ids.push(tag_id);
				$(this).addClass('selected');
			}
			else
			{
				selected_filter_tag_ids.splice(position_in_array, 1);
				$(this).removeClass('selected');
			}

			if (JSON.stringify(selected_filter_tag_ids.sort()) === JSON.stringify(confirmed_filter_tag_ids.sort()))
			{
				$('#get_gardening_apply_tag_filters').prop('disabled', true);
			}
			else
			{
				$('#get_gardening_apply_tag_filters').prop('disabled', false);
			}
		}
	});

	// Apply tag filters
	$('#get_gardening_apply_tag_filters').on('click', function(){
		fetch_get_gardening_entries();
	});

	// Cancel a tag filter
	$(document).on('click', '#get_gardening_selected_tag_filters .tag_btn', function(){
		var tag_id = String($(this).data('tag-id'));
		if (tag_id)
		{
			var position_in_array = selected_filter_tag_ids.indexOf(tag_id);
			if (position_in_array !== -1)
			{
				selected_filter_tag_ids.splice(position_in_array, 1);

				fetch_get_gardening_entries();
			}
		}
	});

	// Clear all tag filters
	$(document).on('click', '#get_gardening_clear_filters', function(){
		$('#get_gardening_tag_filters .inner').hide();
		$('#get_gardening_tag_filters').fadeIn(100);
	
		selected_filter_tag_ids = [];
		fetch_get_gardening_entries();
	});

	// Load more guides
	$(document).on('click', '#get_gardening_load_more', function(){
		
		var offset = $(this).data('offset');

		$('#get_gardening_tag_filters .inner').hide();
		$('#get_gardening_tag_filters').fadeIn(100);

		jQuery.ajax({
			method: 'GET',
			url: '/ajax/get-gardening-entries-load-more',
			data: {
				offset: offset,
				category: current_category_id,
				tags: confirmed_filter_tag_ids,
			},
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			success: function(data) {
				$('#get_gardening_entries').append(data);

				if (parseInt($('#get_gardening_entries').data('total-entries')) > offset + 6)
				{
					$('#get_gardening_load_more').data('offset', offset + 6);
				}
				else
				{
					$('#get_gardening_load_more').hide();
				}
			},
			error: function(data) {
				alert("There was a problem. Please try again...");
			},
			complete: function() {
				$('#get_gardening_tag_filters').fadeOut(250);
				
				setTimeout(function(){
					$('#get_gardening_tag_filters .inner').show();
				}, 250);
			},
		});
	});

	// Make the jump links 'sticky' when scrolling below a certain point
	function setup_jump_links()
	{
		if ($('.entry_body').length && $('body.section_get_gardening #anchors').length)
		{
			var $anchors = $('body.section_get_gardening #anchors');
			var position = $('.entry_body').offset().top - $(window).scrollTop()
			if (position < 16)
			{
				$anchors.addClass('sticky');
			}
			else
			{
				$anchors.removeClass('sticky');
			}

			// Fade it out if we start to overlap with the footer
			if (position + $('.entry_body').outerHeight() - $anchors.outerHeight() < 0)
			{
				$anchors.fadeOut(250);
			}
			else
			{
				$anchors.fadeIn(250);
			}

			// Highlight the current jump link in the menu
			var current = null;
			$('.entry_body .anchor').each(function(){
				if ($(this).closest('.block').offset().top - $(window).scrollTop() < 0)
				{
					current = $(this).attr('href');
				}
			});
			$('body.section_get_gardening #anchors .sub_heading_nav_link').removeClass('current');
			if (current)
			{
				$('body.section_get_gardening #anchors a[href="' + current + '"]').closest('.sub_heading_nav_link').addClass('current');
			}
		}
	}
	setup_jump_links();
	$(window).on('scroll', function(){
		setup_jump_links();
	});

	// Hide certain modals when resizing window
	$(window).on('resize', function(){
		$('#get_gardening_category_filters').fadeOut(250);
	})

	// Go back to top
	$('#anchor_back_to_top').on('click', function(event){
		event.preventDefault();
        $('html, body').animate({
            scrollTop: 0
        }, 750);
        return false;
	});

	// 'Make it easier' accordions
	$('.accordion_heading').on('click', function(){
		var accordion = $(this).closest('.accordion');
		if (accordion.hasClass('open'))
		{
			accordion.removeClass('open');
			accordion.find('.accordion_contents').slideUp(250);
		}
		else
		{
			accordion.addClass('open');
			accordion.find('.accordion_contents').slideDown(250);
		}
	});

	// Show extra fields when selecting a checkbox in the e-news sign up form
	var enews_form_open = false;
	$('#enews_signup_form input[type="email"]').on('change', function(){
		if (!enews_form_open)
		{
			enews_form_open = true;
			$('#enews_signup_form_hidden_fields').slideDown(250);
		}
	});

});
