jQuery(document).ready(function($) {

    // Animate scroll to internal anchor points
    $('a[href^="#"]:not(a[href="#"])').on('click', function(event) {
        event.preventDefault();
        var target_element = $(this.hash);
        var padding = $(this).hasClass('no_padding') ? 2 : -22;
        $('html, body').animate({
            scrollTop: target_element.offset().top + padding
        }, 750);
        return false;
    });

    // Primary navigation
    $('body').on('click', function(event) {
        if ($('#primary_navigation > li.active').length){
            $('#primary_navigation > li.active').removeClass('active').find('.primary_navigation_section').slideUp(250);
        }
    });
    $('#primary_navigation > li').on('click', function(event) {
        event.stopPropagation();
        var section = $(this);
        if ( section.hasClass('active') ){
            section.removeClass('active').find('.primary_navigation_section').slideUp(250);
        } else {
            // Close any open navigation sections first
            if ($('#primary_navigation > li.active').length){
                $('#primary_navigation > li.active').removeClass('active').find('.primary_navigation_section').slideUp(250, function() {
                    // Now open this one
                    section.addClass('active').find('.primary_navigation_section').slideDown(250);
                });
            } else {
                section.addClass('active').find('.primary_navigation_section').slideDown(250);
            }
        }
    });

    // Mobile menu
    $('#menu_btn').on('click', function(event) {
        $(this).toggleClass('menu_open');
        $('#header_navigation_wrapper').slideToggle();
    });

});
