jQuery(document).ready(function($)
{
	$('#donate_widget form').on('submit', function(event)
	{
		event.preventDefault();
		var form = $(this);
		form.find('.donation_type input[type=radio]').prop('disabled', true);

		form.off('submit').submit();

		return false;
	});

	$('#donate_widget .donation_type input').on('change', function(event)
	{
		var type = $(this).val();
		var form = $(this).closest('form');

		$('.donation_type label').removeClass('active');
		$('.donation_amounts, .donation_amount_benefit').removeClass('visible');
		form.find('.donation_amounts input[type=radio]').prop('checked', false);

		if (type == 'monthly')
		{
			$('.donation_type_monthly').addClass('active');
			$('.donation_amounts_monthly').addClass('visible');
			$('.donation_amounts_monthly input').first().trigger('click');

			form.attr('action', '/donate-form-monthly');
		}
		else if (type == 'single')
		{
			$('.donation_type_single').addClass('active');
			$('.donation_amounts_single').addClass('visible');
			$('.donation_amounts_single input').first().trigger('click');

			form.attr('action', '/donate-form-single');
		}

		// form.find('input[type=submit]').prop('disabled', true);
	});

	$('#donate_widget .donation_amounts input').on('change', function(event)
	{
		var amount = $(this).val();
		var form = $(this).closest('form');

		$('.donation_amount_benefit').removeClass('visible');
		$('.donation_amount_benefit[data-amount='+amount+']').addClass('visible');

		if ($('#donation_cover_transaction_fees_field').prop('checked'))
		{
			$('#checkout #donation_amount').val($(this).attr('data-amount-with-fees')).trigger('change');
		}
		else
		{
			$('#checkout #donation_amount').val(amount).trigger('change');
		}

		form.find('input[type=submit]').prop('disabled', false);
	});

	$('#donation_cover_transaction_fees_field').on('change', function(event)
	{
		// other amount values
		var transaction_fee = $('#donate_widget #donation_other_amount_field').attr('data-transaction-fee');
		var other_amount_value = parseFloat($('#donate_widget #donation_other_amount_field').val());
		
		if ($(this).prop('checked'))
		{
			$('.donation_amounts input').each(function(index)
			{
				var amount_with_fees = Number($(this).attr('data-amount-with-fees'));
				if (!Number.isInteger(amount_with_fees))
				{
					amount_with_fees = parseFloat(Math.round(amount_with_fees * 100) / 100).toFixed(2);
				}
				// change the donation value
				$(this).val($(this).attr('data-amount-with-fees'));
				$(this).siblings('.amount').text('£'+amount_with_fees);
			});
			// change the donation value of other amount
			var otehr_amout_value_with_fee = parseFloat(other_amount_value + (other_amount_value * transaction_fee)).toFixed(2);
			$('#donate_widget #donation_other_amount_field').val(otehr_amout_value_with_fee);
			$('#checkout #donation_amount').val($('#donate_widget .donation_amounts input:checked').attr('data-amount-with-fees')).trigger('change');
		}
		else
		{
			$('.donation_amounts input').each(function(index)
			{
				var amount = $(this).attr('data-amount');
				$(this).siblings('.amount').text('£'+amount);
			});
			// change the donation value of other amount
			var otehr_amout_value_with_no_fee = parseFloat(other_amount_value - (other_amount_value * transaction_fee)).toFixed(2);
			$('#donate_widget #donation_other_amount_field').val(otehr_amout_value_with_no_fee);
			$('#checkout #donation_amount').val($('#donate_widget .donation_amounts input').val()).trigger('change');
		}
	});

	// if other amount changes when transation fee is already ticked
	$('#donate_widget #donation_other_amount_field').on('change', function(event) {
		var transaction_fee = $(this).attr('data-transaction-fee');
		var other_amount_value = parseFloat($(this).val());
		
		if ($('#donation_cover_transaction_fees_field').prop('checked')) {
			var otehr_amout_value_with_fee = parseFloat(other_amount_value + (other_amount_value * transaction_fee)).toFixed(2);
			$(this).val(otehr_amout_value_with_fee);
		}
	});

	$('#donate_widget #donation_other_amount_field').on('keyup', function(event)
	{
		var amount = Number($(this).val());
		var form = $(this).closest('form');

		if (!isNaN(amount) ? amount >= 1 : false)
		{
			$('.donation_amounts input').prop('disabled', true);
			form.find('input[type=submit]').prop('disabled', false);
			$('#checkout #donation_amount').val(amount).trigger('change');
		}
		else
		{
			$('.donation_amounts input').prop('disabled', false);
			// form.find('input[type=submit]').prop('disabled', true);
		}
	});

	// Update gift aid amount display if user changes donation amount in donation widget
	$('#donate_widget #donation_amount').on('change', function(event) {
		var amount = $(this).val();
		$('#gift_aid .amount').text(new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(amount));
		$('#gift_aid .amount_plus_gift_aid').text(new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(amount * 1.25));
	});

	// Select single by default and the smallest donation
	if ($('#donate_widget').length)
	{
		if ($('.donation_type_single').length)
		{
			$('.donation_type_single input').trigger('click');
			$('.donation_amounts_single input').first().trigger('click');
		}
		else
		{
			// $('.donation_type_monthly input').trigger('click');
			$('.donation_amounts_monthly input').first().trigger('click');
		}
		$(this).find('input[type=submit]').prop('disabled', false);
	}

	$('#checkout_no_donation_field').on('change', function(event)
	{
		if ($(this).prop('checked'))
		{
			$('#donate_widget .donate_box').hide();
		}
		else
		{
			$('#donate_widget .donate_box').show();
		}
	});

});
