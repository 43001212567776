jQuery(document).ready(function($)
{
	// Checkout - billing and shipping
	$('.address-fieldset').each(function () {

		var $addressFieldset = $(this);

		$('.address-select', $addressFieldset).each(function () {

			this.$element = $(this);
			this.$radio = $('.radio input', this.$element);
			this.$body = $('.body', this.$element);

			this.$radio.change($.proxy(function () {
				$('.body', $addressFieldset).addClass('hidden');
				this.$body.hide();
			}, this));

			if (this.$radio.is(':checked')) {
				this.$body.show();
			}
		});

	});

	$("#shippingAddressSameAsBilling").change(function (e) {
		var checked = $(this).prop('checked');
		if (checked)
		{
			$('.addressBox.shippingAddress').hide();
			$('.addressBox.shippingAddress input, .addressBox.shippingAddress select').prop('disabled', true);
		}
		else
		{
			$('.addressBox.shippingAddress').show();
			$('.addressBox.shippingAddress input, .addressBox.shippingAddress select').prop('disabled', false);
		}
	});

	if ($("#shippingAddressSameAsBilling").prop('checked'))
	{
		$('.addressBox.shippingAddress').hide();
		$('.addressBox.shippingAddress input, .addressBox.shippingAddress select').prop('disabled', true);
	}

	$('select.address-country').change(function ()
	{
		// get the value of the selected country.
		var cid = $(this).val();
		var $states = $(this).closest('.addressBox').find('select.address-stateId');
		var $stateName = $(this).closest('.addressBox').find('input.address-stateName');
		$states.find('option').remove();

		if (states.hasOwnProperty(cid))
		{
			$states.closest('.field_group').show();

			// We have states for this country, show the states drop down.
			$states.show();
			$states.attr('name', $states.data('modelname')+'[stateValue]');

			// We have states for this country, hide the stateName input.
			$stateName.removeAttr('name');
			$stateName.hide();
			$stateName.val('');

			// Add all states as options to drop down.
			for (var id in states[cid])
			{
				var state = states[cid][id];
				var $option = $('<option/>');
				$option.attr('value', id).text(state);
				$states.append($option);
			}

		} else {
			$states.closest('.field_group').hide();
			// hide the states dropdown, since this country has none.
			$states.hide();
			$states.removeAttr('name');

			// show the stateName
			$stateName.show();
			$stateName.attr('name', $stateName.data('modelname')+'[stateValue]');
		}

	});

	$('.attendee_is_purchaser_checkbox').change(function ()
	{
		if ($(this).prop('checked'))
		{
			$(this).closest('.attendee').find('.attendee_fields').hide();
		}
		else
		{
			$(this).closest('.attendee').find('.attendee_fields').show();
		}
	});

	// Checkout - Payment
	$('#paymentCurrency').change(function()
	{
		$('form#gateway').submit();
	});

	$('#paymentMethod input').change(function(event)
	{
		var $radio = $(this);

		if ($radio.val().length === 0) {
			return;
		}

		var parts = $radio.val().split(':');

		var name = 'gatewayId';

		if (parts[0] === 'card') {
			name = 'paymentSourceId';
		}

		$radio.prop('disabled', 'disabled');

		$('form#gateway').append('<input type="hidden" name="' + name + '" value="' + parts[1] + '" />').submit();
	});

	// Checkout - stripe
	$('.stripe-payment-intents-form').each(function()
	{
        $container = $(this);

        function updateErrorMessage(event) {
            var displayError = $('.card-errors', $container).get(0);

            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        }

        var stripe = Stripe($container.data('publishablekey'));
        var elements = stripe.elements({
			fonts: [
				{
					cssSrc: '/stripe_fonts.css',
				},
			],
		});

        // Create an instance of the card Element
        var card = elements.create('card', {
				style: {
					base: {
						fontFamily: 'UniversLTPro, Arial, sans-serif',
						fontSize: '18px',
						lineHeight: '24px',
						color: '#283228',
					},
					invalid: {
						'color': '#e42313',
					},
				},
                hidePostalCode: true
            }
        );

		// Responsive
		$(window).on('resize', function(event)
		{
			if ($(window).width() <= 600) {
				card.update({style: {
					base: {
						fontSize: '16px',
					}
				}});
			} else {
				card.update({style: {
					base: {
						fontSize: '18px',
					}
				}});
			}
		});

        card.addEventListener('change', updateErrorMessage);

        // Add an instance of the card Element into the `card-element` <div>
        card.mount($('.card-data', $container).empty().get(0));

        var $form = $container.closest('form');

        // Remove already bound events
        $form.off('submit');

        $form.on('submit', function(ev) {
            ev.preventDefault();

			// console.log('processing...');

            // If form submitted already, disregard.
            if ($form.data('processing')) {
                return false;
            }

			// console.log('A');

            $form.data('processing', true);

            // Compose card holder info
            var cardHolderName, orderEmail, ownerAddress;

            if ($('.card-holder-first-name', $form).length > 0 && $('.card-holder-last-name', $form).length > 0) {
                cardHolderName = $('.card-holder-first-name', $form).val() + ' ' + $('.card-holder-last-name', $form).val();
            }

            if ($('.stripe-address', $form).length > 0) {
                ownerAddress = {
                    'line1': $('input[name=stripe-line1]', $form).val(),
                    'city': $('input[name=stripe-city]', $form).val(),
                    'postal_code': $('input[name=stripe-postal-code]', $form).val(),
                    'country': $('input[name=stripe-country]', $form).val(),
                };
            }

            orderEmail = $('input[name=orderEmail]').val();

            // Tokenize the credit card details and create a payment source
            stripe.createPaymentMethod('card', card, {
				billing_details: {
					name: cardHolderName,
					email: orderEmail,
					address: ownerAddress
				}
			}).then(function(result) {
                if (result.error) {
                    updateErrorMessage(result);
                    $form.data('processing', false);
                } else {
                    // Add the payment source token to the form.
                    $form.append($('<input type="hidden" name="paymentMethodId"/>').val(result.paymentMethod.id));
                    $form.get(0).submit();
                }
            });

			return false;
        });
    });

});
