$(document).ready(function() {

    // Filtering
    $('#course_filters input').on('change', function(event)
    {
        var selected_filters = $('#course_filters input:checked');

        if (selected_filters.length)
        {
            $('.course_thumb_wrapper').hide();
            var filter_ids = [];
            $('#course_filters input:checked').each(function(index) {
                filter_ids.push('.cat_' + $(this).val());
            });
            var filter_ids_string = '.course_thumb' + filter_ids.join('');
            console.log(filter_ids_string);
            $(filter_ids_string).parent('.course_thumb_wrapper').show();
        }
        else
        {
            $('.course_thumb_wrapper').show();
        }
    });

    $('.course_filter_title').on('click', function(event)
    {
        $(this).closest('.course_filter').toggleClass('open');
    });

    // Course info modal
	$('.course_info_btn').on('click', function(event)
	{
		var course = $(this).closest('.course_thumb');

		var course_title = course.attr('data-title');
		var course_excerpt = course.attr('data-excerpt');
		var course_url = course.attr('data-url');

		var modal = $('.modal_wrapper.course_info_modal');

		modal.find('.modal_title a').text(course_title).attr('href',course_url);
		modal.find('.modal_excerpt').empty().text(course_excerpt);
		modal.find('.button').attr('href',course_url);

		modal.addClass('visible');
	});

});
