var shift_key_pressed = false;
var enter_key_pressed = false;

$(document).ready(function() {

    $(document).keydown(function (event) {
        var keycode = (event.keyCode ? event.keyCode : event.which);
        if (keycode == '13')
        {
            enter_key_pressed = true;
        }
        if (keycode == '16')
        {
            shift_key_pressed = true;
        }
        if (shift_key_pressed && enter_key_pressed)
        {
            $('html').toggleClass('devmode');
        }
    });

    $(document).keyup(function (event) {
        var keycode = (event.keyCode ? event.keyCode : event.which);
        if (keycode == '13')
        {
            enter_key_pressed = false;
        }
        if (keycode == '16')
        {
            shift_key_pressed = false;
        }
    });

});
